import Student from '../../model/Student';
import {
    Button,
    Checkbox,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {UserDataText} from './UserPanel';
import {GridProps} from '@mui/material/Grid/Grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import FormTextField from "../UI/FormTextField";
import FormDropdown from "../UI/FormDropdown";
import OlympicsContext from "../../store/olympics-context";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import MainButton from "../UI/MainButton";
import isEmail from "validator/lib/isEmail";
import dayjs from "dayjs";
import SnackbarContext from "../../store/snackbar-context";
import {sendCertificateData} from "../../api/user-api";
import AuthContext from "../../store/auth-context";
import {cleanUpHtml} from "../../utils/string-tools";

const formErrors = {
    emailError: 'Wprowadź poprawny email',
    birthdayError: 'Wprowadź poprawną datę urodzenia',
    birthdayPlaceError: 'Wprowadź poprawne miejsce urodzenia',
    streetError: 'Proszę wpisać ulicę',
    buildingError: 'Proszę wpisać numer budynku',
    apartmentError: '',
    zipCodeError: 'Proszę wpisać poprawny kod pocztowy',
    cityError: 'Proszę uzupełnić pole z miastem',
    sectionError: 'Proszę wybrać dział tematu ustnego etapu III',
    topicError: 'Proszę wybrać temat ustnego etapu III',
};

const Row = (props: GridProps) => {
    return (
        <Grid
            item
            container
            justifyContent={'space-between'}
            {...props}
        >
            {props.children}
        </Grid>
    );
};

type Props = {
    student: Student | null;
};
const UserQuestionnaire = ({student}: Props) => {
    const LANGUAGE_CATEGORY = "JĘZYKOZNAWSTWO";
    const {palette} = useTheme();
    const [validated, setValidated] = useState(false);
    const [expand, setExpand] = useState(false);
    const initialEmail = student?.certEmail ? student?.certEmail : student?.email ? student?.email : ''
    const initialStreet = student?.certStreet ? student?.certStreet : student?.street ? student?.street : ''
    const initialBuilding = student?.certBuilding ? student?.certBuilding : student?.building ? student?.building : ''
    const initialApartment = student?.certApartment ? student?.certApartment : student?.apartment ? student?.apartment : ''
    const initialZipCode = student?.certZipCode ? student?.certZipCode : student?.zipCode ? student?.zipCode : ''
    const initialCity = student?.certCity ? student?.certCity : student?.city ? student?.city : ''
    const editDisabled = student?.topic?.category || student?.topic?.topic

    const INITIAL_STATE = {
        email: {value: initialEmail, error: !isEmail(initialEmail)},
        birthday: {value: dayjs(student?.birthdate).locale('pl'), error: !student?.birthdate},
        birthdayPlace: {value: student?.birthdatePlace, error: !student?.birthdatePlace},
        street: {value: initialStreet, error: initialStreet.length <= 2},
        building: {value: initialBuilding, error: initialBuilding.length < 1},
        apartment: {value: initialApartment, error: false},
        zipCode: {value: initialZipCode, error: initialZipCode.length != 6},
        city: {value: initialCity, error: initialCity.length <= 2},
        section: {value: student?.topic?.category, error: true},
        topic: {value: student?.topic?.topic, error: true},
        linguisticTopic: {value: student?.topic?.linguisticTopic, error: true},
    }

    const {topics, declarations} = useContext(OlympicsContext);
    const {token, id} = useContext(AuthContext);
    const {setMsg} = useContext(SnackbarContext);
    const [formInfo, setFormInfo] = useState(INITIAL_STATE);
    const [openDialog, setOpenDialog] = useState(false);
    const [sendingRequest, setSendingRequest] = useState(false);
    const [formDeclarations, setFormDeclarations] = useState<any[]>([])

    useEffect(() => {
        const copy = declarations.map((d: any) => {
            // @ts-ignore
            if (student?.declarationsIds.includes(d.id)) {
                return {...d, checked: true}
            } else {
                return d
            }
        })
        setFormDeclarations(copy)
    }, [declarations])

    const formValueHandler = (value: any, accessor: string, validator: () => boolean) => {
        setFormInfo((prev) => {
            return {
                ...prev,
                [accessor]: {value: value, error: !validator()}
            }
        })
    }

    const validate = () => {
        setValidated(true)
        if (formDeclarations.filter(d => d.required && !d.checked).length > 0) {
            setMsg({msg: 'Zaznacz wszystkie wymagane deklaracje'})
            return false;
        }
        for (const [key, value] of Object.entries(formInfo)) {
            if (value.error) {
                setMsg({msg: 'Wypełnij poprawnie formularz'})
                return false;
            }
        }
        return true
    }

    const prepareData = () => {
        return {
            id: id,
            certEmail: formInfo.email.value,
            certStreet: formInfo.street.value,
            certBuilding: formInfo.building.value,
            certApartment: formInfo.apartment.value,
            certZipCode: formInfo.zipCode.value,
            certCity: formInfo.city.value,
            category: formInfo.section.value,
            topic: formInfo.topic.value,
            linguisticTopic: formInfo.linguisticTopic.value,
            declarations: formDeclarations.filter((d: any) => d.checked).map((d: any) => d.id),
            birthday: formInfo.birthday.value,
            birthdayPlace: formInfo.birthdayPlace.value,
        }
    }

    const handleSave = () => {
        if (validate()) {
            setOpenDialog(true)
        }

    }
    const saveData = () => {
        setSendingRequest(true)
        const data = prepareData()
        sendCertificateData(token, data)
            .then((response) => {
                window.location.reload()
            })
            .finally(() => {
                setSendingRequest(false)
                setOpenDialog(false)
            })

    }

    const getCategory = () => {
        return [... new Set(topics.filter((t:any) => t.specializations.map((t:any) => t.id).includes(student?.specialization.id)).map((t: any) => t.category))]
    }

    return (
        <>
            <Grid
                item
                container
                justifyContent={'space-between'}
                flexWrap={'nowrap'}
                mt={5}
                onClick={() => setExpand(!expand)}
                sx={{cursor: 'pointer'}}
            >
                <Grid
                    item
                    container
                    gap={'10px'}
                >
                    <Typography
                        variant={'h4'}
                        fontWeight={'500'}
                        color={palette.primary.main}
                        sx={{
                            marginRight: '20px'
                        }}
                    >
                        Ankieta
                    </Typography>
                    {
                        !editDisabled && <>
                            <WarningAmberIcon sx={{color: palette.warning.main}}/>
                            <Typography
                                fontWeight={'500'}
                                color={palette.warning.main}
                            >
                                Wymagane dane
                            </Typography>
                        </>
                    }
                </Grid>
                {
                    expand ? <KeyboardArrowUpIcon sx={{color: palette.primary.main}}/> :
                        <KeyboardArrowDownIcon sx={{color: palette.primary.main}}/>
                }
            </Grid>
            <Divider
                variant={'fullWidth'}
                sx={{
                    backgroundColor: palette.secondary.light,
                    borderBottomWidth: '3px',
                }}
            />
            <Collapse
                in={expand}
                sx={{
                    maxWidth: '100%'
                }}
            >
                <Grid
                    item
                    container
                    justifyContent={'start'}
                    width={'100%'}
                    gap={'24px'}
                >
                    <Row
                        mt={5}
                    >
                        <UserDataText
                            title={'Wybór tematu ustnego etapu III'}
                        />
                    </Row>
                    <Row>
                        <UserDataText
                            title={'Literatura lub teatr'}
                        />
                        <FormDropdown
                            id={'topic_category'}
                            label={'Dział'}
                            isDisabled={editDisabled}
                            validated={validated}
                            errorMsg={formInfo.section.error ? formErrors.sectionError : ''}
                            value={formInfo.section.value}
                            values={getCategory()}
                            valueChangeHandler={(value) => formValueHandler(value, 'section', () => !!value)}
                            labelSx={{
                                mt: '20px'
                            }}
                        />
                        <FormDropdown
                            id={'topic'}
                            label={'Temat'}
                            isDisabled={editDisabled}
                            validated={validated}
                            errorMsg={formInfo.topic.error ? formErrors.topicError : ''}
                            value={formInfo.topic.value}
                            values={topics.filter((t:any) => t.category == formInfo.section.value).map((t:any) => t.topic)}
                            valueChangeHandler={(value) => formValueHandler(value, 'topic', () => !!value)}
                        />
                    </Row>
                    <Row>
                        <UserDataText
                            title={'Językonznawstwo'}
                        />
                        <FormDropdown
                            id={'linguistic_topic'}
                            label={'Temat'}
                            isDisabled={editDisabled}
                            validated={validated}
                            errorMsg={formInfo.linguisticTopic.error ? formErrors.topicError : ''}
                            value={formInfo.linguisticTopic.value}
                            values={topics.filter((t:any) => t.is_linguistic == 1).map((t:any) => t.topic)}
                            valueChangeHandler={(value) => formValueHandler(value, 'linguisticTopic', () => !!value)}
                            labelSx={{
                                mt: '20px'
                            }}
                        />
                    </Row>
                    <Row
                        mt={5}
                    >
                        <UserDataText
                            title={'Dane niezbędne do zaświadczenia'}
                        />
                    </Row>
                    <Row
                        flexWrap={'nowrap'}
                        gap={'24px'}
                    >
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale='pl'
                        >
                            <DatePicker
                                label='Data urodzenia'
                                value={formInfo.birthday.value}
                                disabled={editDisabled}
                                maxDate={dayjs()}
                                slotProps={{textField: {size: 'small', error: validated && formInfo.birthday.error}}}
                                onChange={(newValue) => {
                                    formValueHandler(newValue, 'birthday', () => !!newValue)
                                }}
                            />
                        </LocalizationProvider>
                        <TextField
                            fullWidth
                            size={'small'}
                            disabled={editDisabled}
                            error={validated && formInfo.birthdayPlace.error}
                            label={'Miejsce urodzenia'}
                            value={formInfo.birthdayPlace.value}
                            onChange={(event) => formValueHandler(event.target.value, 'birthdayPlace', () => event.target.value.length > 2)}
                        />
                    </Row>
                    <Row>
                        <TextField
                            fullWidth
                            size={'small'}
                            disabled={editDisabled}
                            error={validated && formInfo.email.error}
                            label={'Adres email do wysyłki zaświadczenia'}
                            value={formInfo.email.value}
                            onChange={(event) => formValueHandler(event.target.value, 'email', () => isEmail(event.target.value))}
                        />
                    </Row>
                    <Row>
                        <UserDataText
                            fullwidth
                            text={'Adres do wysyłki zaświadczenia jeżeli jest inny niż przy rejestracji'}
                        />
                    </Row>
                    <Row>
                        <FormTextField
                            id={'street'}
                            type={'text'}
                            label={'Ulica'}
                            placeholder='Ulica'
                            disabled={editDisabled}
                            validated={validated}
                            errorMsg={formInfo.street.error ? formErrors.streetError : ''}
                            value={formInfo.street.value}
                            valueChangeHandler={(value) => formValueHandler(value, 'street', () => value.length > 2)}
                        />
                    </Row>
                    <Row
                        flexWrap={'nowrap'}
                        gap={'24px'}
                    >
                        <FormTextField
                            id={'building'}
                            type={'text'}
                            label={'Numer budynku'}
                            placeholder='Numer budynku'
                            disabled={editDisabled}
                            validated={validated}
                            errorMsg={formInfo.building.error ? formErrors.buildingError : ''}
                            value={formInfo.building.value}
                            valueChangeHandler={(value) => formValueHandler(value, 'building', () => value.length > 0)}
                        />
                        <FormTextField
                            id={'apartment'}
                            type={'text'}
                            label={'Numer mieszkania'}
                            placeholder='Numer mieszkania'
                            disabled={editDisabled}
                            validated={validated}
                            errorMsg={formErrors.apartmentError}
                            value={formInfo.apartment.value}
                            valueChangeHandler={(value) => formValueHandler(value, 'apartment', () => true)}
                        />
                    </Row>
                    <Row
                        flexWrap={'nowrap'}
                        gap={'24px'}
                    >
                        <FormTextField
                            id={'zip'}
                            type={'text'}
                            label={'Kod pocztowy'}
                            placeholder='__-___'
                            disabled={editDisabled}
                            validated={validated}
                            errorMsg={formInfo.zipCode.error ? formErrors.zipCodeError : ''}
                            value={formInfo.zipCode.value}
                            valueChangeHandler={(value) => formValueHandler(value, 'zipCode', () => value.length == 6)}
                        />
                        <FormTextField
                            id={'city'}
                            type={'text'}
                            label={'Miasto'}
                            placeholder='Miasto'
                            disabled={editDisabled}
                            validated={validated}
                            errorMsg={formInfo.city.error ? formErrors.cityError : ''}
                            value={formInfo.city.value}
                            valueChangeHandler={(value) => formValueHandler(value, 'city', () => value.length > 2)}
                        />
                    </Row>
                    <Row
                        mt={5}
                    >
                        <UserDataText
                            title={'Deklaracja'}
                        />
                    </Row>
                    <Row>
                        {formDeclarations.map((d: any, i: number) => <FormControlLabel
                            key={'declaration-' + i}
                            control={
                                <Checkbox
                                    checked={d.checked}
                                    sx={{
                                        borderRadius: 3,
                                        color: validated && d.required && !d.checked && 'red',
                                        width: 16,
                                        height: 16,
                                        mb: '5px'
                                    }}
                                />
                            }
                            disabled={!!editDisabled}
                            label={<div
                                dangerouslySetInnerHTML={{__html: cleanUpHtml((d.required ? '* ' : '') + d.declaration)}}
                            />}
                            onChange={(e: any) => setFormDeclarations((prev) => {
                                const copy = [...prev]
                                copy[i].checked = !copy[i].checked
                                return copy
                            })}
                            sx={{
                                display: 'flex',
                                alignItems: 'end',
                                gap: '20px',
                                ml: '2px',
                                mb: '20px',
                            }}
                        />)}
                    </Row>
                    {!editDisabled && <Row
                        justifyContent={'center'}
                        flexDirection={'column'}
                    >
                        <MainButton
                            title={'Zapisz'}
                            darkVersion={true}
                            sx={{
                                alignSelf: 'center',
                                lineHeight: '20px',
                                letterSpacing: '0.18px',
                                backgroundColor: palette.warning.main
                            }}
                            onClick={handleSave}
                        />
                    </Row>}
                </Grid>
            </Collapse>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(!openDialog)}
            >
                <DialogTitle sx={{fontSize: '24px'}}>Podane dane w ankiecie nie mogą zostać później zmienione. Czy na
                    pewno zapisać?</DialogTitle>
                <DialogContent>
                    <DialogActions
                        sx={{
                            display: "flex",
                            justifyContent: 'space-around'
                        }}
                    >
                        <Button
                            variant={'contained'}
                            color={'error'}
                            onClick={() => setOpenDialog(false)}
                        >
                            Anuluj
                        </Button>
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            onClick={saveData}
                        >
                            {sendingRequest ? <CircularProgress/> : 'Zapisz'}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default UserQuestionnaire;
